import * as XLSX from "xlsx";
import Papa from "papaparse";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Dialog,
  Paper,
  MenuItem,
  IconButton,
  Menu,
  Fade,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import TabPanel from "./TabPanel";
import { MdDeleteForever } from "react-icons/md";
import RefreshLogo from "assets/Icons/refreshLogo.svg";
import DataTableCustom from "components/DataTableCustom";
import SearchIcon from "assets/Icons/SearchIcon.svg";
import MapIcon from "assets/Icons/MapIcon.svg";
import EditIcon from "assets/Icons/EditIcon.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EditEnumeratorForm from "components/Forms/EditEnumeratorForm";
import AddEnumeratorForm from "components/Forms/AddEnumeratorForm";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledMenu } from "./StylesOptionMenu";
import SelectWavesModal from "components/modals/SelectWavesModal";
import ProvideAccessModal from "./ProvideAccessModal";
import {
  useGetAllUsers,
  useGetCurrentUsers,
  useGetEnumerator,
  useGetStudyUsers,
  useGetWaves,
} from "./manageEmployeeQueries";
import ActivateWaveModal from "components/modals/ActivateWaveModal";
import RemoveAccessModal from "./RemoveAccessModal";
import { getLocalStorageItem } from "hooks";
import SupervisorStateToggler from "./SupervisorStatetoggler/SupervisorStateToggler";
import { changeDateToLocal } from "pages/Dashboard";

const ManageEmployees = () => {
  // useState
  // const [employeeDetailModal, setEmployeeDetailModal] = useState(false);
  // const [searchBulkData, setSearchBulkData] = useState([]);
  // const [removeAccessModal, setRemoveAccessModal] = useState(false);
  // const [changeModal, setChangeModal] = useState('');
  // const [selectedActiveFilters, setSelectedActiveFilters] = useState({});
  // const [selectedInActiveFilters, setSelectedInActiveFilters] = useState({});
  const [tabValue, setTabValue] = useState("users");
  const [selectedWaveEnuIds, setSelectedWaveEnuIds] = useState({});
  const [optionAnchorEl, setOptionAnchorEl] = React.useState(null);
  const [userTypePaginationInfo, setUserTypePaginationInfo] =
    React.useState(null);
  // const [grantInactiveUser, setGrantInactiveUser] = useState(null);

  const [activePaginationUsers, setActivePaginationUsers] = useState({
    page: 1,
    perPage: 25,
    orderBy: "desc",
  });

  const [activePaginationEnumerators, setActivePaginationEnumerators] =
    useState({
      page: 1,
      perPage: 25,
      orderBy: "desc",
    });

  // const [inActivePagination, setInActivePagination] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    nameOrEmpIdOrTransId: "",
    factory: "",
  });
  const [grantInactiveUser, setGrantInactiveUser] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchBulkData, setSearchBulkData] = useState([]);
  const [userType, setUserType] = useState("current");
  const [downloadFileData, setDownloadFileData] = useState([]);
  const [wave, setWave] = useState(0);
  const [selectedWaveValue, setSelectedWaveValue] = useState("");
  const [enumeratorData, setEnumeratorData] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openWaveDialog, setOpenWaveDialog] = useState(false);
  const [openEmployeeDetailModal, setOpenEmployeeDetailModal] = useState(false);
  const [openRemoveAccessModal, setOpenRemoveAccessModal] = useState();
  const [openActivateWaveModal, setOpenActivateWaveModal] = useState(false);

  const [anchorDownloadMenu, setAnchorDownloadMenu] = React.useState(null);
  const downloadMenuOpen = Boolean(anchorDownloadMenu);
  const handleDownloadMenu = (event) => {
    setAnchorDownloadMenu(event.currentTarget);
  };
  const handleDownloadMenuClose = () => {
    setAnchorDownloadMenu(null);
  };

  // modals handlers
  const handleClickOpenWaveDialog = () => {
    setOpenWaveDialog(true);
    setOptionAnchorEl(null);
  };

  const handleClickCloseWaveDialog = () => {
    setOpenWaveDialog(false);
    // const emptyUsers = [];
    // setSelectedUsers(emptyUsers);
  };

  const handleProvideAccessOpenModal = (data) => {
    //setChangeModal(data);
    setOpenEmployeeDetailModal(true);
  };
  const handleProvideAccessCloseModal = () => {
    setOpenEmployeeDetailModal(false);
    handleClickCloseWaveDialog();
  };

  const handleOpenActivateWaveModal = () => {
    setOpenActivateWaveModal(true);
  };

  const handleCloseOpenActivateWaveModal = () => {
    setOpenActivateWaveModal(false);
  };

  const handleWaveSettingClick = () => {
    handleOpenActivateWaveModal();
  };

  const handleRemoveAccessOpenModal = (data) => {
    setOptionAnchorEl(null);
    setOpenRemoveAccessModal(true);
  };
  const handleRemoveAccessCloseModal = () => {
    setOpenRemoveAccessModal(false);
  };

  const setDataForEmployeeDetail = (selectedWave) => {
    setSelectedWaveValue(selectedWave);
    handleProvideAccessOpenModal();
  };

  const getDataFromTableInManageEmp = (selectedUsers) => {
    setSelectedUsers(selectedUsers);
  };
  //
  // const handleEmployeeDetailOpenModal = () => {
  //   setEmployeeDetailModal(true);
  // };
  // const handleEmployeeDetailCloseModal = () => {
  //   setEmployeeDetailModal(false);
  // };

  // const handleRemoveAccessOpenModal = (data) => {
  //   setChangeModal(data);
  //   setRemoveAccessModal(true);
  // };
  // const handleRemoveAccessCloseModal = () => {
  //   setRemoveAccessModal(false);
  // };

  const handleNameOrEmpIdOrTransIdFilter = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      search: e.target.value,
    });
  };
  const handleFactoryFilter = (e) => {
    setSelectedFilters({
      ...selectedFilters,
      factoryCode: e.target.value,
    });
  };
  const handleActiveFilter = () => {
    setActivePaginationUsers({
      ...activePaginationUsers,
      factoryCode: selectedFilters?.factoryCode,
      search: selectedFilters?.search,
    });
  };
  const handleInActiveFilter = () => {
    setActivePaginationEnumerators({
      ...activePaginationEnumerators,
      factoryCode: selectedFilters?.factoryCode,
      search: selectedFilters?.search,
    });
  };

  // const { data: activeUserData, dataUpdatedAt } = useGetAllActiveUsersQuery({
  //   activePagination,
  //   selectedActiveFilters,
  // });

  // const { data: inActiveUserData, dataUpdatedAt: inActiveUpdatedAt } =
  //   useGetAllInActiveUsersQuery({
  //     inActivePagination,
  //     selectedInActiveFilters,
  //   });

  // const { activeUserList, activePaginationInformation } = useMemo(() => {
  //   if (dataUpdatedAt) {
  //     return {
  //       activeUserList: activeUserData?.data?.results,
  //       activePaginationInformation: activeUserData?.data?.options,
  //     };
  //   } else {
  //     return { activeUserList: [], activePaginationInformation: {} };
  //   }
  // }, [dataUpdatedAt]);

  // const { inActiveUserList, inActivePaginationInformation } = useMemo(() => {
  //   if (inActiveUpdatedAt) {
  //     return {
  //       inActiveUserList: inActiveUserData?.data?.results,
  //       inActivePaginationInformation: inActiveUserData?.data?.options,
  //     };
  //   } else {
  //     return { inActiveUserList: [], inActivePaginationInformation: {} };
  //   }
  // }, [inActiveUpdatedAt]);
  const includeActiveStatusColumn = true; // Replace with your condition

  const usersColumns = useMemo(() => {
    const columns = [
      {
        Header: "Employee Id",
        accessor: "employeeId",
      },
      {
        Header: "Name",
        accessor: "user.name",
      },
      {
        Header: "Factory code",
        accessor: "factoryCode",
      },
      {
        Header: "GBL Supervisor Id",
        accessor: "enumeratorId",
      },
      {
        Header: "Phone no.",
        accessor: "user.phone",
      },
    ];

    if (userType === "study") {
      columns.push({
        Header: "Active Status",
        accessor: "status",
        Cell: ({ cell: { value }, row }) => (
          <div>{value === "UNDER_STUDY" && <p>Under Study</p>}</div>
        ),
      });
    }
    if (userType === "current") {
      columns.push({
        Header: "Wave",
        accessor: "wave.name",
        Cell: ({ cell: { value }, row }) => <div>{value}</div>,
      });
    }
    if (userType !== "all") {
      columns.push({
        Header: "Username",
        accessor: "user.id",
        Cell: ({ cell: { value }, row }) => (
          <div>{row.original.employeeId}</div>
        ),
      });
    }
    if (userType !== "all") {
      columns.push({
        Header: "Password",
        accessor: "user.password",
        Cell: ({ cell: { value }, row }) => <div>{value}</div>,
      });
    }

    return columns;
  }, [userType]);

  const enumeratorsColumns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Factory code",
        accessor: "worker.factoryCode",
      },
      {
        Header: "Total Workers Assigned",
        accessor: "enumeratorWorkers.length",
      },
      {
        Header: "Phone no.",
        accessor: "phone",
      },
      {
        Header: "GBL Supervisor Id",
        accessor: "worker.employeeId",
      },
      {
        Header: "Password",
        accessor: "password",
        Cell: ({ cell: { value }, row }) => (
          <div className="flex justify-between items-center gap-1 pr-[10px]">
            <p>{value}</p>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                handleEditClick(row);
              }}
            >
              <img src={EditIcon} className="w-[24px] h-[24px]" alt="" />
            </div>
          </div>
        ),
      },
      {
        Header: "Active/Inactive",
        accessor: "isActive",
        Cell: ({ cell: { value }, row }) => {
          return (
            <div className="w-full flex justify-center items-center">
              <SupervisorStateToggler
                active={row.original.worker.accessibilityStatus}
                id={row.original.worker.id}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  //Dialog for the edit enumerator
  const handleEditClick = (row) => {
    setEnumeratorData(row.original);
    handleClickOpenEditDialog();
  };
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const open = Boolean(optionAnchorEl);
  const handleOptionClick = (event) => {
    setOptionAnchorEl(event.currentTarget);
  };
  const handleOptionClose = () => {
    setOptionAnchorEl(null);
  };

  const handleClickOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  //Dialog for the add enumerator
  const handleAddEnumerator = () => {
    handleClickOpenAddDialog();
  };
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  // const columns1 = useMemo(
  //   () => [
  //     {
  //       Header: 'Employee Id',
  //       accessor: 'empId',
  //     },
  //     {
  //       Header: 'Name',
  //       accessor: 'name',
  //     },
  //     { Header: 'Factory code', accessor: 'factory' },
  //     {
  //       Header: 'Enumerator code',
  //       accessor: 'enumeratorCode',
  //     },
  //     {
  //       Header: 'Phone no.',
  //       accessor: 'mobile',
  //     },
  //     {
  //       Header: 'User name',
  //       accessor: 'username',
  //     },

  //     {
  //       Header: 'Password',
  //       accessor: 'password',
  //     },
  //   ],
  //   []
  // );

  const { data: allUsersData, dataUpdatedAt: allUsersDataUpdateAt } =
    useGetAllUsers(activePaginationUsers);

  const { allUserDataLists, allUsersPaginationInfo } = useMemo(() => {
    if (allUsersDataUpdateAt) {
      return {
        allUserDataLists: allUsersData?.data?.records,
        allUsersPaginationInfo: allUsersData?.data?.meta,
      };
    } else {
      return { allUserDataLists: [], allUsersPaginationInfo: {} };
    }
  }, [allUsersDataUpdateAt]);

  const { data: currentUsersData, dataUpdatedAt: currentUsersDataUpdateAt } =
    useGetCurrentUsers(activePaginationUsers);

  const { currentUsersDataLists, currentUsersPaginationInfo } = useMemo(() => {
    if (currentUsersDataUpdateAt) {
      return {
        currentUsersDataLists: currentUsersData?.data?.records,
        currentUsersPaginationInfo: currentUsersData.data.meta,
      };
    } else {
      return { currentUsersDataLists: [], currentUsersPaginationInfo: {} };
    }
  }, [currentUsersDataUpdateAt]);

  const { data: studyUsersData, dataUpdatedAt: studyUsersDataUpdateAt } =
    useGetStudyUsers(activePaginationUsers);

  const { studyUsersDataLists, studyUsersPaginationInfo } = useMemo(() => {
    if (studyUsersDataUpdateAt) {
      return {
        studyUsersDataLists: studyUsersData?.data?.records,
        studyUsersPaginationInfo: studyUsersData?.data?.meta,
      };
    } else {
      return { studyUsersDataLists: [], studyUsersPaginationInfo: {} };
    }
  }, [studyUsersDataUpdateAt]);

  const {
    data: enumeratorDataPaginated,
    dataUpdatedAt: enumeratorDataUpdateAt,
  } = useGetEnumerator(activePaginationEnumerators);

  const { enumeratorDataLists, enumeratorPaginationInfo } = useMemo(() => {
    if (enumeratorDataUpdateAt) {
      return {
        enumeratorDataLists: enumeratorDataPaginated?.data?.records,
        enumeratorPaginationInfo: enumeratorDataPaginated?.data?.meta,
      };
    } else {
      return { enumeratorDataLists: [], enumeratorPaginationInfo: {} };
    }
  }, [enumeratorDataUpdateAt]);

  useEffect(() => {
    if (tabValue === "users") {
      switch (userType) {
        case "current":
          setActivePaginationUsers({
            ...activePaginationUsers,
            page: 1,
          });
          break;
        case "study":
          setActivePaginationUsers({
            ...activePaginationUsers,
            page: 1,
          });
          break;
        case "all":
          setActivePaginationUsers({
            ...activePaginationUsers,
            page: 1,
          });
          break;
        default:
          break;
      }
    }
  }, [userType, tabValue]);
  useEffect(() => {
    switch (userType) {
      case "current":
        setUserTypePaginationInfo(currentUsersPaginationInfo);
        break;
      case "study":
        setUserTypePaginationInfo(studyUsersPaginationInfo);
        break;
      case "all":
        setUserTypePaginationInfo(allUsersPaginationInfo);
        break;
      default:
        break;
    }
  }, [
    userType,
    allUsersPaginationInfo,
    currentUsersPaginationInfo,
    studyUsersPaginationInfo,
  ]);

  useEffect(() => {
    switch (userType) {
      case "current":
        setWave(0);
        setTableData(currentUsersDataLists);
        break;
      case "study":
        if (wave > 0) {
          setActivePaginationUsers({
            ...activePaginationUsers,
            waveId: wave,
          });
          setTableData(studyUsersDataLists);
        } else {
          setActivePaginationUsers({
            ...activePaginationUsers,
            waveId: null,
          });
          setTableData(studyUsersDataLists);
        }
        break;
      case "all":
        setWave(0);
        setTableData(
          searchBulkData.length > 0 ? searchBulkData : allUserDataLists
        );
        break;
      default:
        break;
    }
  }, [
    currentUsersDataUpdateAt,
    studyUsersDataUpdateAt,
    allUsersDataUpdateAt,
    tabValue,
    userType,
    searchBulkData,
    wave,
  ]);
  const { data: wavesData, isLoading } = useGetWaves();

  function handleUsersDataDownload() {
    let userData = currentUsersDataLists;
    if (userType === "all") {
      userData = allUserDataLists;
    }
    if (userType === "study") {
      userData = studyUsersDataLists;
    }
    const dataList = downloadFileData.length > 0 ? downloadFileData : userData;

    const data = dataList.map((user) => [
      user?.employeeId,
      user?.user.name,
      user?.factoryCode,
      user?.enumeratorId,
      user?.user.phone,
    ]);

    const csvData = Papa.unparse([
      ["Id", "Name", "Factory Code", "GBL Supervisor Id", "Phone no"],
      ...data,
    ]);

    return csvData;
  }
  function handleEnumeratorsDataDownload() {
    const dataList =
      downloadFileData.length > 0 ? downloadFileData : enumeratorDataLists;

    const data = dataList.map((enumerator) => [
      enumerator?.id,
      enumerator?.name,
      enumerator?.worker.factoryCode,
      enumerator?.enumeratorWorkers.length,
      enumerator?.phone,
      enumerator?.worker.employeeId,
      enumerator?.password,
      enumerator?.role,
      enumerator?.worker.accessibilityStatus === "CAN_LOGIN" ? true : false,
      changeDateToLocal(enumerator?.createdAt, true),
      changeDateToLocal(enumerator?.updatedAt, true),
    ]);

    const csvData = Papa.unparse([
      [
        "Id",
        "Name",
        "factoryCode",
        "worker assigned",
        "Phone no",
        "GBL Supervisor Id",
        "Password",
        "Role",
        "isActive",
        "createdAt",
        "updatedAt",
      ],

      ...data,
    ]);

    return csvData;
  }

  const handleCsvExcel = (value) => {
    setAnchorDownloadMenu(null);
    setOptionAnchorEl(null);
    //download CSV file
    if (value === "Download CSV") {
      let csvData;
      if (tabValue === "users") {
        csvData = handleUsersDataDownload();
      }
      if (tabValue === "enumerators") {
        csvData = handleEnumeratorsDataDownload();
      }

      const csvBlob = new Blob([csvData], {
        type: "text/csv;charset=utf-8;",
      });
      const csvUrl = URL.createObjectURL(csvBlob);
      const link = document.createElement("a");
      link.href = csvUrl;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    //download excel sheet
    else if (value === "Excel Sheet") {
      let jsonToXLData = currentUsersDataLists;
      if (userType === "all") {
        jsonToXLData = allUserDataLists;
      }
      if (userType === "study") {
        jsonToXLData = studyUsersDataLists;
      }
      if (tabValue === "enumerators") {
        const filteredListForExcel = enumeratorDataLists?.map((enumerator) => {
          return {
            id: enumerator?.id,
            Name: enumerator?.name,
            factoryCode: enumerator?.worker.factoryCode,
            workerAssigned: enumerator?.enumeratorWorkers.length,
            Phone: enumerator?.phone,
            GBLSupervisorId: enumerator?.worker.employeeId,
            Password: enumerator?.password,
            isActive:
              enumerator?.worker.accessibilityStatus === "CAN_LOGIN"
                ? true
                : false,
            createdAt: changeDateToLocal(enumerator?.createdAt, true),
            updatedAt: changeDateToLocal(enumerator?.updatedAt, true),
          };
        });
        console.log(filteredListForExcel);

        jsonToXLData = filteredListForExcel;
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(jsonToXLData);
      XLSX.utils.book_append_sheet(wb, ws, "data");
      XLSX.writeFile(wb, "data.xlsx");
    }
  };
  const user = getLocalStorageItem("USER_ROLE");

  if (isLoading) return <></>;

  if (user === "SUPER_ADMIN") {
    return (
      <div className="w-[95%] pt-6 mx-auto self-start">
        <ProvideAccessModal
          open={openEmployeeDetailModal}
          handleClose={handleProvideAccessCloseModal}
          grantInactiveUser={downloadFileData}
          setGrantInactiveUser={setSelectedUsers}
          selectedWaveEnuIds={selectedWaveEnuIds}
          setInactiveGrantUser={setSelectedUsers}
          changeModal="1"
        />
        <RemoveAccessModal
          open={openRemoveAccessModal}
          handleClose={handleRemoveAccessCloseModal}
          grantInactiveUser={downloadFileData}
          setGrantInactiveUser={setSelectedUsers}
          changeModal="1"
        />

        <div className="flex items-end justify-between gap-2">
          <TabPanel
            tabvalue={tabValue}
            setTabValue={setTabValue}
            setSelectedFilters={setSelectedFilters}
          />
          <div className="flex self-center gap-3 ">
            <div className="lg:hidden">
              {tabValue === "users" && userType === "study" && (
                <IconButton
                  color="secondary"
                  aria-label="add an alarm"
                  onClick={() => handleWaveSettingClick()}
                >
                  <SettingsIcon />
                </IconButton>
              )}
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleOptionClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Options
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={optionAnchorEl}
                open={open}
                onClose={handleOptionClose}
              >
                {tabValue !== "users" && (
                  <MenuItem onClick={() => handleAddEnumerator()}>
                    Add GBL Supervisor
                  </MenuItem>
                )}
                {userType === "current" && (
                  <MenuItem
                    disabled={selectedUsers?.length === 0 ? true : false}
                    onClick={() => handleRemoveAccessOpenModal()}
                  >
                    Remove Access
                  </MenuItem>
                )}
                {userType === "all" && tabValue === "users" && (
                  <MenuItem onClick={() => handleClickOpenWaveDialog()}>
                    Provide Access
                  </MenuItem>
                )}
                <MenuItem>Download</MenuItem>
              </StyledMenu>
            </div>
            <div className="flex self-start gap-3 max-lg:hidden">
              {tabValue !== "users" && (
                <Button
                  onClick={() => handleAddEnumerator()}
                  variant="outlined"
                  color="primary"
                  className="font-semibold font-[Inter] py-2 px-4 capitalize "
                  // onClick={() => handleRemoveAccessOpenModal('1')}
                >
                  add GBL Supervisor
                </Button>
              )}
              {tabValue === "users" && userType === "study" && (
                <IconButton
                  color="secondary"
                  aria-label="add an alarm"
                  onClick={() => handleWaveSettingClick()}
                >
                  <SettingsIcon />
                </IconButton>
              )}
              {tabValue === "users" && userType === "current" && (
                <Button
                  disabled={selectedUsers?.length === 0 ? true : false}
                  variant="outlined"
                  color="primary"
                  className="font-semibold font-[Inter] py-2 px-4 capitalize "
                  startIcon={<MdDeleteForever className="w-[24px] h-[24px]" />}
                  onClick={() => handleRemoveAccessOpenModal()}
                >
                  Remove Access
                </Button>
              )}
              {tabValue === "users" && userType === "all" && (
                <Button
                  variant="outlined"
                  color="primary"
                  className="font-semibold font-[Inter] py-2 px-4 capitalize"
                  disabled={selectedUsers?.length === 0 ? true : false}
                  onClick={() => handleClickOpenWaveDialog()}
                >
                  Provide Access
                </Button>
              )}
              {userType === "all" && tabValue === "users" ? (
                <></>
              ) : (
                <>
                  <Button
                    id="download-button"
                    aria-controls={
                      downloadMenuOpen ? "download-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={downloadMenuOpen ? "true" : undefined}
                    className="text-white max-lg:hidden  capitalize bg-gradient-to-b tracking-wide from-[#A1A4EE] to-[#3D3E78]  font-semibold rounded-[8px] border-none px-5 py-2"
                    onClick={handleDownloadMenu}
                    startIcon={<FileDownloadOutlinedIcon />}
                  >
                    Download
                  </Button>
                  <Menu
                    id="download-menu"
                    MenuListProps={{
                      "aria-labelledby": "download-button",
                    }}
                    anchorEl={anchorDownloadMenu}
                    open={downloadMenuOpen}
                    onClose={handleDownloadMenuClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem onClick={() => handleCsvExcel("Download CSV")}>
                      Download CSV
                    </MenuItem>
                    <MenuItem onClick={() => handleCsvExcel("Excel Sheet")}>
                      Excel Sheet
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
          </div>
        </div>
        <Grid
          container
          className="bg-[#E8EBFA] flex justify-between items-center flex-nowrap  gap-2 p-2 rounded-b-lg  shadow-md"
        >
          <div className="flex items-center justify-between gap-2">
            <Grid item>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    maxWidth: "250px",
                    borderRadius: "8px",
                  },
                }}
                value={selectedFilters?.search || ""}
                placeholder="Name/Id"
                type="text"
                onChange={handleNameOrEmpIdOrTransIdFilter}
                className="rounded-lg"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="bg-[3D3E78]">
                      <img
                        src={SearchIcon}
                        alt="search"
                        className="bg-[#ECF6FF]"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    maxWidth: "190px",
                    borderRadius: "8px",
                  },
                  "& input::placeholder": {
                    fontSize: "14px",
                  },
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                value={selectedFilters?.factoryCode || ""}
                placeholder="Factory code"
                type="text"
                onChange={handleFactoryFilter}
                className="rounded-lg"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="bg-[#E8EBFA]">
                      <img
                        src={MapIcon}
                        alt="Location Logo"
                        className="bg-[#ECF6FF]"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item className="rounded-lg">
              <Button
                variant="outlined"
                sx={{
                  height: "39px",
                  borderRadius: "8px",
                  backgroundColor: "#E8EBFA",
                }}
                onClick={() => {
                  setActivePaginationUsers({
                    page: 1,
                    perPage: 25,
                    orderBy: "desc",
                  });
                  setActivePaginationEnumerators({
                    page: 1,
                    perPage: 25,
                    orderBy: "desc",
                  });
                  setSelectedFilters({
                    factoryCode: "",
                    search: "",
                  });
                  setSearchBulkData([]);
                  setUserTypePaginationInfo(
                    (userType === "study" && studyUsersPaginationInfo) ||
                      (userType === "current" && currentUsersPaginationInfo) ||
                      (userType === "all" && allUsersPaginationInfo)
                  );
                }}
              >
                <img
                  src={RefreshLogo}
                  alt="Refresh Logo"
                  className="w-[24px] h-[24px] p-1"
                />
              </Button>
            </Grid>
          </div>

          <Grid conatiner className="">
            <Grid item className="">
              <Button
                variant="standard"
                onClick={
                  tabValue === "users"
                    ? handleActiveFilter
                    : handleInActiveFilter
                }
                className="py-1 text-sm font-semibold text-white capitalize whitespace-nowrap px-9 bg-[#3D3E78]"
              >
                Show Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid conatiner className="">
          {tabValue === "users" ? (
            <DataTableCustom
              wavesData={wavesData?.data}
              userType={userType}
              wave={wave}
              setWave={setWave}
              getDataFromTableInManageEmp={getDataFromTableInManageEmp}
              updateFilters={setActivePaginationUsers}
              data={tableData}
              columns={usersColumns}
              pageFilter={activePaginationUsers}
              paginationInformation={userTypePaginationInfo}
              setDownloadFileData={setDownloadFileData}
              isChecked
              selectedTab={tabValue}
              setUserType={setUserType}
              setSearchBulkData={setSearchBulkData}
            />
          ) : (
            <DataTableCustom
              selectedTab={tabValue}
              getDataFromTableInManageEmp={getDataFromTableInManageEmp}
              data={enumeratorDataLists}
              pageFilter={activePaginationEnumerators}
              columns={enumeratorsColumns}
              setDownloadFileData={setDownloadFileData}
              updateFilters={setActivePaginationEnumerators}
              paginationInformation={enumeratorPaginationInfo}
            />
          )}
        </Grid>
        <Dialog
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          maxWidth="658px"
        >
          <Paper className="w-[658px] rounded-lg shadow-md overflow-auto">
            <EditEnumeratorForm
              closeDialog={handleCloseEditDialog}
              enumeratorData={enumeratorData}
            />
          </Paper>
        </Dialog>

        <Dialog
          open={openAddDialog}
          onClose={handleCloseAddDialog}
          maxWidth="658px"
        >
          <Paper className="w-[41rem]  rounded-lg shadow-md overflow-auto">
            <AddEnumeratorForm closeDialog={handleCloseAddDialog} />
          </Paper>
        </Dialog>

        <Dialog open={openWaveDialog} onClose={handleClickCloseWaveDialog}>
          <Paper className="w-[360px] h-[350px] px-10 py-10 rounded-lg shadow-md overflow-auto">
            <SelectWavesModal
              empIds={downloadFileData}
              setSelectedWaveEnuIds={setSelectedWaveEnuIds}
              handleCloseDialog={handleClickCloseWaveDialog}
              setDataForEmployeeDetail={setDataForEmployeeDetail}
            />
          </Paper>
        </Dialog>
        <Dialog
          open={openActivateWaveModal}
          onClose={handleCloseOpenActivateWaveModal}
        >
          <Paper className="w-[250px] h-[230px] px-10 py-10 rounded-lg shadow-md overflow-auto">
            <ActivateWaveModal
              handleCloseDialog={handleCloseOpenActivateWaveModal}
            />
          </Paper>
        </Dialog>
      </div>
    );
  } else {
    return <>Login With Super Admin</>;
  }
};

export default ManageEmployees;
