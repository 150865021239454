import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import CrossIcon from "../../assets/Icons/CrossIcon.svg";
import SaveIcon from "../../assets/Icons/SaveIcon";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as yup from "yup";
import {
  Box,
  Divider,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  useGetEnumeratorDetails,
  useUpdateEnumerator,
} from "pages/ManageEmployees/manageEmployeeQueries";
import { useQueryClient } from "@tanstack/react-query";
import EnumeratorTable from "./EnumeratorTable";
import { yupResolver } from "@hookform/resolvers/yup";

const AddEnumeratorFormSchema = yup.object().shape({
  name: yup.string().required("Full Name is a required field"),
  factoryCode: yup.string().required("Factory Code is a required field"),
  employeeId: yup
    .string()
    .required("Enumerator ID is a required field")
    .min(1, "Enumerator Id must at least 6 characters")
    .max(10, "Password must not exceed 10 character"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must not exceed 20 characters"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required Field")
    .matches(/^\+91\d{10}$/, "Phone Number will start with +91"),
});

const EditEnumeratorForm = ({ closeDialog, enumeratorData }) => {
  const [enumeratorWorkers, setEnumeratorWorkers] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(AddEnumeratorFormSchema) });

  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [selectedWave, setSelectedWave] = useState(0);
  const [showPassword, setShowPassword] = useState(true);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    //event.preventDefault();
  };

  const { mutate } = useUpdateEnumerator();

  const values = [
    {
      value: "M21",
      label: "M21",
    },
    {
      value: "M03",
      label: "M03",
    },
    {
      value: "49M",
      label: "49M",
    },
    {
      value: "37M",
      label: "37M",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Employee Id",
        accessor: "employeeId",
      },
      {
        Header: "Name",
        accessor: "user.name",
      },
      {
        Header: "Factory code",
        accessor: "factoryCode",
      },
      {
        Header: "Wave Id",
        accessor: "wave.name",
      },
    ],
    []
  );

  const star = " * ";

  const {
    data: enumeratorDetails,
    isLoading,
    error,
    isSuccess,
  } = useGetEnumeratorDetails(enumeratorData.id);

  const onSubmit = (data) => {
    const ids = selectedWorkers?.map((worker) => worker?.id);
    const allIds = enumeratorDetails.data?.enumeratorWorkers?.map(
      (worker) => worker?.id
    );
    const filterWaveData = enumeratorDetails.data?.enumeratorWorkers
      ?.filter((worker) => worker?.wave?.id === selectedWave)
      .map((worker) => worker?.id);
    const finalIds =
      selectedWave !== 0
        ? [...ids, ...allIds.filter((allId) => !filterWaveData.includes(allId))]
        : ids;

    if (finalIds.length > 0) {
      mutate(
        {
          ...data,
          id: String(enumeratorDetails.data.id),
          workerIds: finalIds,
        },
        {
          onSuccess: () => {
            closeDialog();
          },
        }
      );
    } else {
      mutate(
        { ...data, id: String(enumeratorDetails.data.id) },
        {
          onSuccess: () => {
            closeDialog();
          },
        }
      );
    }
  };

  useEffect(() => {
    if (enumeratorDetails?.data) {
      setEnumeratorWorkers(enumeratorDetails.data?.enumeratorWorkers);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (enumeratorDetails?.data) {
      if (selectedWave > 0) {
        const filterWaveData =
          enumeratorDetails.data?.enumeratorWorkers?.filter(
            (worker) => worker?.wave?.id === selectedWave
          );
        setEnumeratorWorkers(filterWaveData);
      } else {
        setEnumeratorWorkers(enumeratorDetails?.data?.enumeratorWorkers);
      }
    }
  }, [selectedWave]);

  if (isLoading) return <></>;
  if (error) return <>Something Went Wrong!!!</>;
  return (
    <div className={`w-full bg-white rounded-lg`}>
      <Box className="flex justify-between px-[35px] pt-[19px] pb-[15px]">
        <Typography className="text-center text-[22px] font-semibold tracking-tighter text-[#070707]">
          GBL Supervisor Data
        </Typography>
        <div
          className="w-[24px] h-[24px] hover:cursor-pointer"
          onClick={closeDialog}
        >
          <img src={CrossIcon} className="w-[24px] h-[24px]" alt="cross icon" />
        </div>
      </Box>
      <Divider />
      <div
        className={`${
          enumeratorDetails.data.enumeratorWorkers.length > 0 ? "h-[70vh]" : ""
        } overflow-y-auto overflow-x-hidden `}
      >
        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            spacing="24px"
            className="px-[80px] pt-[20px] pb-[30px]"
          >
            <Box className="flex justify-between items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Full Name <span className="text-red-500">{star}</span>:
              </label>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    width: "300px",
                    borderRadius: "10px",
                  },
                }}
                {...register("name")}
                defaultValue={enumeratorDetails.data.name}
                type="text"
                className="rounded-lg"
                inputProps={{
                  inputMode: "text",
                  pattern: "^[A-Za-z -]+$",
                }}
                error={Boolean(errors?.name?.message)}
                helperText={errors?.name?.message}
              />
            </Box>
            <Box className="flex justify-between items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Factory Code<span className="text-red-500">{star}</span>:
              </label>
              <TextField
                id="outlined-select-currency"
                select
                // defaultValue={values[0].value}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    width: "300px",
                    borderRadius: "10px",
                  },
                }}
                className="rounded-lg"
                {...register("factoryCode")}
                defaultValue={enumeratorData.worker.factoryCode}
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors?.factoryCode?.message)}
                helperText={errors?.factoryCode?.message}
              >
                {values.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box className="flex justify-between items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                GBL Supervisor ID <span className="text-red-500">{star}</span>:
              </label>
              <TextField
                defaultValue={enumeratorDetails.data.worker.employeeId}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    width: "300px",
                    borderRadius: "10px",
                  },
                }}
                {...register("employeeId")}
                type="text"
                className="rounded-lg"
                error={Boolean(errors?.employeeId?.message)}
                helperText={errors?.employeeId?.message}
              />
            </Box>
            <Box className="flex justify-between items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Password <span className="text-red-500">{star}</span>:
              </label>
              <TextField
                type={showPassword ? "text" : "password"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    width: "300px",
                    borderRadius: "10px",
                  },
                }}
                defaultValue={enumeratorDetails.data.password}
                {...register("password")}
                error={Boolean(errors?.password?.message)}
                helperText={errors?.password?.message}
                className="rounded-lg"
                inputProps={{
                  endadornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleToggleShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                  inputMode: "text",
                  pattern: "^[A-Za-z -]+$",
                }}
              />
            </Box>
            <Box className="flex justify-between items-center">
              <label
                htmlFor=""
                className="text-[#1B2559] text-[16px]  font-[700] leading-[100%]"
              >
                Phone number<span className="text-red-500">{star}</span>:
              </label>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "50px",
                    width: "300px",
                    borderRadius: "10px",
                  },
                }}
                {...register("phoneNumber")}
                error={Boolean(errors?.phoneNumber?.message)}
                helperText={errors?.phoneNumber?.message}
                type="text"
                className="rounded-lg"
                defaultValue={enumeratorDetails.data.phone}
              />
            </Box>
          </Stack>
          {enumeratorDetails.data.enumeratorWorkers.length > 0 && (
            <Box className="pl-[80px] pr-[15px]">
              <EnumeratorTable
                data={enumeratorWorkers}
                columns={columns}
                headerForDailyStats="Assigned Workers"
                isDialogBox={true}
                isChecked
                setSelectedWorkers={setSelectedWorkers}
                setSelectedWave={setSelectedWave}
              />
            </Box>
          )}
        </form>
      </div>
      <Divider />
      <Box className="flex justify-center py-[24px]">
        <Button
          // height: 48px;
          // padding: 24px;
          variant="contained"
          className="capitalize h-[48px] w-[164px]"
          startIcon={<SaveIcon />}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          <p>Save</p>
        </Button>
      </Box>
    </div>
  );
};

export default EditEnumeratorForm;
