import { MenuItem, Select } from "@mui/material";
import paramsKey from "constants/paramsKey";
import { format } from "date-fns";
import useControlParams from "hooks/useControlParams";
import { useGetWaves } from "pages/ManageEmployees/manageEmployeeQueries";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLastDate, getStartDate } from "utils/dateFunctions";
import CustomDownloadButton from "./CustomDownloadButton";
import CustomDropdown from "./CustomDropdown";
import CustomMonthDayWeekSelector from "./CustomMonthDayWeekSelector";
import { DownloadIcon } from "./Icons";
import Tabs from "./Tabs";

export default function StatisticsNavbar() {
  const { getParamValue, navigateWithParams, navigateWithMultipleParams } =
    useControlParams();
  const { data } = useGetWaves();
  const [wave, setWave] = useState(getParamValue(paramsKey.waveKey) || 0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleWaveChange = async (value) => {
    setWave(value);
    if (value === 0) {
      const currentParams = new URLSearchParams(location.search);
      currentParams.delete(paramsKey.waveKey);
      navigate(`${location.pathname}?${currentParams.toString()}`);
    } else {
      navigateWithParams(paramsKey.waveKey, value);
    }
  };

  return (
    <div className="flex justify-between flex-wrap gap-2">
      <div className="flex items-stretch gap-2">
        <CustomMonthDayWeekSelector />
        <Tabs
          activeOption={getParamValue(paramsKey.filterKey) || ""}
          options={["All", "Weekly", "Monthly", "Daily"]}
          onClick={(value) => {
            const startDate = getStartDate(value);
            const lastDate = getLastDate(value);
            const obj = {};
            obj[paramsKey.filterKey] = value;
            if (value === "Monthly") {
              obj[paramsKey.currentMonth] = new Date().getMonth();
            } else {
              obj[paramsKey.currentMonth] = null;
            }

            if (value === "All") {
              obj[paramsKey.startDateKey] = "2023-10-01";
              obj[paramsKey.endDateKey] = format(new Date(), "yyyy-MM-dd");
            } else {
              obj[paramsKey.startDateKey] = startDate;
              obj[paramsKey.endDateKey] = lastDate;
            }

            navigateWithMultipleParams(obj);
          }}
        />
        <CustomDropdown
          heading={"Gender"}
          value={getParamValue(paramsKey.genderKey) || ""}
          options={["Male", "Female"]}
          handleSelect={(value) => {
            navigateWithParams(paramsKey.genderKey, value);
          }}
        />
        <CustomDropdown
          heading={"Factory"}
          value={getParamValue(paramsKey.factoryKey) || ""}
          options={["37M", "49M", "M21", "M03", "K11", "K12"]}
          handleSelect={(value) => {
            navigateWithParams(paramsKey.factoryKey, value);
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <Select
          labelId="my-select-placeholder"
          id="demo-simple-select"
          onChange={async (e) => {
            await handleWaveChange(e.target.value);
          }}
          className="flex gap-2 items-center h-[40px] rounded-[10px] bg-[#FFFFFF99] border-[1px] p-5 border-[#fff]"
          value={wave}
        >
          <MenuItem value={0}>All Waves</MenuItem>
          {data?.data
            ?.sort((a, b) => a.id - b.id)
            .map((wave) => (
              <MenuItem value={wave?.id} key={wave.name}>
                {wave?.name}
              </MenuItem>
            ))}
        </Select>
        <CustomDownloadButton
          onClick={() => {
            window.print();
          }}
        >
          <div className="flex items-center gap-2">
            <DownloadIcon />
            <span className="text-white text-xl">Download</span>
          </div>
        </CustomDownloadButton>
      </div>
    </div>
  );
}
