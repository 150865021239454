import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import paramsKey from "constants/paramsKey";
import { format } from "date-fns";
import useControlParams from "hooks/useControlParams";
import {
  convertMiliToDate,
  dailyMiliSecond,
  dateFormator,
  getDaysInMonth,
  getMiliSeconds,
  weekMiliSecond,
} from "utils/dateFunctions";
import DatePicker from "react-datepicker";
import { CustomInput } from "pages/Dashboard";
import { useEffect, useState } from "react";
export default function CustomMonthDayWeekSelector() {
  const { getParamValue, navigateWithMultipleParams } = useControlParams();
  const dateObj = {
    startDate: getParamValue(paramsKey.startDateKey),
    endDate: getParamValue(paramsKey.endDateKey),
  };
  const currentMonth = Number(getParamValue(paramsKey.currentMonth));
  const totalNoOfDays = getDaysInMonth(currentMonth, new Date().getFullYear());
  const filterParamValue = getParamValue(paramsKey.filterKey);
  const [dateRange, setDateRange] = useState([
    dateObj.startDate ? new Date(dateObj.startDate) : null,
    dateObj.endDate ? new Date(dateObj.endDate) : null,
  ]);
  let startDateRange, endDateRange;
  if (Array.isArray(dateRange) && dateRange.length === 2) {
    [startDateRange, endDateRange] = dateRange;
  } else {
    startDateRange = null;
    endDateRange = null;
  }
  function handleDateFilter() {
    return `${dateFormator(dateObj.startDate, "dd MMM,yy")} - ${dateFormator(
      dateObj.endDate,
      "dd MMM,yy"
    )}`;
  }
  function returnMiliSecAccToFilter() {
    if (filterParamValue === "Weekly") {
      return weekMiliSecond;
    }
    if (filterParamValue === "Monthly") {
      return totalNoOfDays * dailyMiliSecond;
    }
    if (filterParamValue === "Daily") {
      return dailyMiliSecond;
    }
  }
  function getStartAndEndDateOfMonth(month) {
    const startDate = new Date(new Date().getFullYear(), month - 1, 1);
    const endDate = new Date(new Date().getFullYear(), month, 0);
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }
  function handleDateLeftAndRight(leftOrRightKey) {
    const startDateMilli = getMiliSeconds(dateObj.startDate);
    const endDateMili = getMiliSeconds(dateObj.endDate);
    const params = {};
    if (filterParamValue !== "Monthly") {
      params.startDate = format(
        convertMiliToDate(
          leftOrRightKey === "left"
            ? startDateMilli - returnMiliSecAccToFilter()
            : startDateMilli + returnMiliSecAccToFilter()
        ),
        "yyyy-MM-dd"
      );
      params.endDate = format(
        convertMiliToDate(
          leftOrRightKey === "left"
            ? endDateMili - returnMiliSecAccToFilter()
            : endDateMili + returnMiliSecAccToFilter()
        ),
        "yyyy-MM-dd"
      );
    }
    params.current_month =
      leftOrRightKey === "left" ? currentMonth - 1 : currentMonth + 1;
    if (filterParamValue === "Monthly") {
      const curentMonthStartAndEndDate = getStartAndEndDateOfMonth(
        (leftOrRightKey === "left" ? currentMonth - 1 : currentMonth + 1) + 1
      );
      params.startDate = format(
        curentMonthStartAndEndDate?.startDate,
        "yyyy-MM-dd"
      );
      params.endDate = format(
        curentMonthStartAndEndDate?.endDate,
        "yyyy-MM-dd"
      );
    }
    navigateWithMultipleParams(params);
  }
  function handleLeftButton() {
    handleDateLeftAndRight("left");
  }
  function handleRightButton() {
    handleDateLeftAndRight("right");
  }
  const handleAllDateRangeFilter = (update) => {
    if (update?.[1]) {
      const dateObjStart = new Date(update?.[0] || null);
      const dateObjEnd = new Date(update?.[1] || null);
      const params = {};
      params.startDate = format(dateObjStart, "yyyy-MM-dd");
      params.endDate = format(dateObjEnd, "yyyy-MM-dd");
      navigateWithMultipleParams(params);
      console.log("CustomMonthDayWeekSelector", update, params);
    }
  };
  useEffect(() => {
    if (filterParamValue === "All") {
      setDateRange([
        dateObj.startDate ? new Date(dateObj.startDate) : null,
        dateObj.endDate ? new Date(dateObj.endDate) : null,
      ]);
    }
  }, [filterParamValue]);
  return (
    <>
      {getParamValue(paramsKey.filterKey) === "All" ? (
        <>
          {" "}
          <div
            item
            // style={{ zIndex: 9999 }}
            className="bg-[#E8Ebfa] border border-solid border-black/25 rounded p-1"
          >
            <DatePicker
              selectsRange={true}
              startDate={startDateRange}
              endDate={endDateRange}
              onChange={(update) => {
                setDateRange(update);
                handleAllDateRangeFilter(update);
              }}
              maxDate={new Date()}
              customInput={<CustomInput />}
              className="react-datepicker__wrapper "
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </>
      ) : (
        <div className="bg-[#E8Ebfa] border border-solid border-black/25 rounded flex items-stretch">
          <div
            tabIndex={0}
            onClick={handleLeftButton}
            className="flex items-center justify-center cursor-pointer px-1"
          >
            <ArrowLeft />
          </div>
          <div className="flex items-center justify-center whitespace-nowrap">
            {handleDateFilter()}
          </div>
          <div
            tabIndex={0}
            onClick={handleRightButton}
            className="flex items-center justify-center cursor-pointer px-1"
          >
            <ArrowRight />
          </div>
        </div>
      )}
    </>
  );
}
