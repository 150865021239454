import { useRowSelect, useTable } from "react-table";
import React, { useState } from "react";
import IndeterminateCheckbox from "components/IndeterminateCheckbox";
import { useEffect } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import AlertModal from "components/modals/AlertModal";
import { useGetWaves } from "pages/ManageEmployees/manageEmployeeQueries";

export default function EnumeratorTable(props) {
  const [singleOrAllCheckbox, setSingleOrAllCheckbox] = useState("");
  const [open, setOpen] = useState(false);
  const { data: waves } = useGetWaves();

  const wavesData = React.useMemo(() => {
    return waves?.data
      ?.map((wave) => ({
        name: wave.name,
        id: wave.id,
      }))
      .sort((a, b) => a.id - b.id);
  }, [waves?.data]);

  const handleClickOpen = (singleOrAllClicked) => {
    setSingleOrAllCheckbox(singleOrAllClicked);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    data,
    columns,
    title,
    selectedWorkers,
    setSelectedWorkers,
    setSelectedWave,
  } = props;
  //states
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { selectedRowIds: {} },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div
              onClick={() => {
                if (getToggleAllRowsSelectedProps().checked) {
                  handleClickOpen("All");
                }
              }}
            >
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div
              onClick={() => {
                if (row.getToggleRowSelectedProps().checked) {
                  handleClickOpen("This");
                }
              }}
            >
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setSelectedWorkers(selectedFlatRows.map((d) => d.original));
  }, [selectedFlatRows]);

  useEffect(() => {
    toggleAllRowsSelected(true);
  }, [data]);

  return (
    <div
      className={`flex flex-col  rounded-md  overflow-x-hidden shadow-md bg-[#333b7a]`}
    >
      <div className="flex items-center justify-between px-4 py-2 max-lg:items-start">
        <div className="capitalize  text-center self-center max-lg:self-start flex items-center w-full justify-between">
          <div className=" text-[#E8EBFA] self-center text-xl text-start font-semibold">
            Assigned Users
          </div>
          <Box className="flex bg-white rounded-[10px]  justify-between items-center">
            <TextField
              id="outlined-select-currency"
              select
              defaultValue={"All Waves"}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "50px",
                  width: "200px",
                  borderRadius: "10px",
                },
              }}
              className="rounded-lg"
              InputLabelProps={{
                shrink: false,
              }}
            >
              <MenuItem onClick={() => setSelectedWave(0)} value={"All Waves"}>
                All Waves
              </MenuItem>
              {wavesData.map((option) => (
                <MenuItem
                  onClick={() => setSelectedWave(option.id)}
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
      </div>

      <div className="tableBoxShdow  bg-[#ECF6FF]">
        <div
          className=" hidden text-lg bg-[#ECF6FF]"
          style={{ borderBottom: "1px solid #ecece" }}
        >
          <p className="text-lg font-semibold"> {title}</p>
        </div>
        <div className="max-h-[50vh] max-md:max-h-[60vh] grow bg-[#E8EBFA] overflow-y-scroll ">
          <table
            className="table w-full border-collapse"
            style={{ borderRight: "1px solid #333B7A" }}
            {...getTableProps()}
          >
            <thead
              style={{
                zIndex: 999,
                borderBottom: "1px solid #333B7A",
              }}
              className="sticky top-0 z-40 align-middle bg-[#E8EBFA] text-semibold"
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  className="align-middle text-[#333B7A] font-semibold border-b border-black tracking-normal px-2 "
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      style={{ borderRight: "1px solid black" }}
                      className="text-center font-bold !p-0"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="w-full h-full" {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    style={{
                      backgroundColor: "#E8EBFA",
                      border: "1px solid #fff",
                      //padding: isChecked ? "100px 0" : ""
                    }}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={{
                            borderRight: "1px solid #333B7A",
                          }}
                          className="text-center !p-0"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {!data.length > 0 && (
          <div className="w-full h-11 bg-[#E8EBFA] font-medium text-center py-10 text-xl">
            No Data
          </div>
        )}
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        singleOrAllText={singleOrAllCheckbox}
        handleClose={handleClose}
      />
    </div>
  );
}
